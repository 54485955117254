@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scrollbar-color: rgb(1, 1, 1) rgb(110, 110, 110);
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(110, 110, 110);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(110, 110, 110);
}

::-webkit-scrollbar-track-piece {
  background: rgb(1, 1, 1);
}

body {
  background-color: rgb(31 41 55);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
